/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~swiper/swiper-bundle.min.css";
@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";

.app.green .mat-toolbar.mat-primary {
  background: #00a859;
  color: white;
}

.app.green .active-link {
  background-color: #00a859;
  color: #fff;
}

.app.green .menu-item .mat-mdc-button:not(.active-link) .menu-icon {
  color: #00a859;
}

.app.green .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #00a859;
  --mdc-protected-button-label-text-color: #fff;
  //font-family: "Arial", sans-serif;
}

// html {
//     font-family: "Arial", sans-serif;
//   }
// body {
//   font-family: "Arial", sans-serif;
// }

.text {
    color: #00a859;
}

.amount {
    color: #00a859;
}


.wallet-text {
    color: #378D3B;
}

.wallet-amount {
    color: #378D3B;
}
.app.green .primary-text {
    color: #00a859;
}

.app.green .warn-text {
  color: #a80006;
}
.menu-title {
  font-family: "Arial", sans-serif;
}

.app.green .product-pagination .ngx-pagination .current {
    background: #00a859;
    color: white;
}

.app.green .breadcrumb a {
    color: #00a859;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    font-family: 'Arial',sans-serif !important;
}
@media (max-width: 768px) {
  .advanced-pie-legend .total-value {
    font-size: 20px !important;
  }

  .advanced-pie-legend .total-label {
    font-size: 16px !important;
    margin-bottom: 19px;
  }

  .advanced-pie-legend
    .legend-items-container
    .legend-items
    .legend-item
    .item-value {
    font-size: 14px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .advanced-pie-legend
    .legend-items-container
    .legend-items
    .legend-item
    .item-label {
    font-size: 14px;
    opacity: 0.7;
    margin-left: 11px;
    margin-top: 0px !important;
  }

  .advanced-pie-legend
    .legend-items-container
    .legend-items
    .legend-item
    .item-percent {
    font-size: 14px !important;
    opacity: 0.7;
    margin-left: 11px;
  }
}
